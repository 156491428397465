<template>
  <div id="ranking-gainers-losers" class="ranking ranking-gainers-losers">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 v-if="type == 'gainers'" class="h4 mb-0 font-size-125 font-family-krona">Gainers</h2>
            <h2 v-if="type == 'losers'" class="h4 mb-0 font-size-125 font-family-krona">Losers</h2>
          </div>
          <div>
            <a href="">More<span class="ml-2"><font-awesome-icon :icon="['fas', 'chevron-right']"/></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body p-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item w-50">
            <a class="nav-link active" :id="'bsc-tab-'+type" data-toggle="tab" :href="'#bsc-'+type" role="tab" :aria-controls="'bsc-'+type" aria-selected="true"><img height="24" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" class="mr-2">BSC</a>
          </li>
          <li class="nav-item w-50">
            <a class="nav-link" :id="'ethereum-tab-'+type" data-toggle="tab" :href="'#ethereum-'+type" role="tab" :aria-controls="'ethereum-'+type" aria-selected="false"><img height="24" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" class="mr-2"/>Ethereum</a>
          </li>
        </ul>

        <div class="tab-content" :id="'tab-content-'+type">
          <div class="tab-pane fade show active" :id="'bsc-'+type" role="tabpanel" :aria-labelledby="'bsc-tab-'+type">
            <table class="table mb-0">
              <tbody>
                <template v-for="(token, index) in tokenListBSC" v-bind:key="index">
                  <tr class="tr-clickable" v-if="index < 5" @click="goToDetail(token)" >
                    <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                      <span class="mr-2">#{{ index + 1 }}</span>
                      <span v-if="token.logo1 != ''"><img class="mr-2" height="24" :src="token.logo1" @error="token.logo1 = ''"/></span>
                      <span v-else-if="token.logo2 != ''"><img class="mr-2" height="24" :src="token.logo2" @error="token.logo2 = ''"/></span>

                      <span v-else class="altLogo mr-2">{{ token.mainToken.symbol[0] }}</span>
                      <span>{{ token.mainToken.symbol }}</span><span class="font-size-075 text-muted">/{{ token.sideToken.symbol }}</span>
                    </td>
                    <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]">$<span v-html="$fixPrice(token.price,4)"></span></td>
                    <td :class="[{'border-0': index == 0}]" class="text-right px-2"><span class="h6 mb-0 ml-2" :class="[{'text-danger':token.variation24h < 0},{'text-success':token.variation24h >= 0}]">{{$fixPrice(token.variation24h, 2)}}%</span></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade" :id="'ethereum-'+type" role="tabpanel" :aria-labelledby="'ethereum-tab-'+type">
            <table class="table mb-0">
              <tbody>
                <template v-for="(token, index) in tokenListEthereum" v-bind:key="index">
                  <tr class="tr-clickable" v-if="index < 5" @click="goToDetail(token)" >
                    <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                      <span class="mr-2">#{{ index + 1 }}</span>
                      <span v-if="token.logo1 != ''"><img class="mr-2" height="24" :src="token.logo1" @error="token.logo1 = ''"/></span>
                      <span v-else-if="token.logo2 != ''"><img class="mr-2" height="24" :src="token.logo2" @error="token.logo2 = ''"/></span>

                      <span v-else class="altLogo mr-2">{{ token.mainToken.symbol[0] }}</span>
                      <span>{{ token.mainToken.symbol }}</span><span class="font-size-075 text-muted">/{{ token.sideToken.symbol }}</span>
                    </td>
                    <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]">$<span v-html="$fixPrice(token.price,4)"></span></td>
                    <td :class="[{'border-0': index == 0}]" class="text-right px-2"><span class="h6 mb-0 ml-2" :class="[{'text-danger':token.variation24h < 0},{'text-success':token.variation24h >= 0}]">{{$fixPrice(token.variation24h, 2)}}%</span></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  props: [
    'type'
  ],
  data() {
    return {
      tokenListBSC: '',
      tokenListEthereum: '',
    };
  },
  created() {
    axios.get(`/v2/tokens/ranking?chain=bsc&type=${this.type}`)
      .then(async (response)=> {
        this.tokenListBSC = response.data.body
        
        for(let i=0; i < this.tokenListBSC.length; i++){
          this.tokenListBSC[i].logo1 = 'https://www.dextools.io/resources/tokens/logos/bsc/' + this.tokenListBSC[i].mainToken.address +'.png';
          this.tokenListBSC[i].logo2 = 'https://www.dextools.io/resources/tokens/logos/3/bsc/' + this.tokenListBSC[i].mainToken.address +'.png';
        }
        axios.get(`/v2/tokens/ranking?chain=ether&type=${this.type}`)
          .then(async (response)=> {
            this.tokenListEthereum = response.data.body
            this.$emit('widgetLoaded');
            for(let i=0; i < this.tokenListEthereum.length; i++){
              this.tokenListEthereum[i].logo1 = 'https://www.dextools.io/resources/tokens/logos/ether/' + this.tokenListEthereum[i].mainToken.address +'.png';
              this.tokenListEthereum[i].logo2 = 'https://www.dextools.io/resources/tokens/logos/3/ether/' + this.tokenListEthereum[i].mainToken.address +'.png';
            }
            this.$emit('widgetLoaded');
          });  
      });

  },
  methods: {
    goToDetail(crypto){
      this.$router.push({ name: 'crypto-detail', params: { address: crypto.mainToken.address, chain: 'bsc' }, query: { symbol: crypto.mainToken.symbol}});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .altLogo{
    background: #444;
    height:24px;
    width:24px;
    display:inline-flex;
    align-items:center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 500;
  }

</style>
